<template>
  <!-- <el-dialog :title="$t('StorageOutKu.OutDetailed')" :visible.sync="shows" top="70px" height="700px" width="840px" lock-scroll append-to-body @close="close"> -->
  <el-drawer :title="$t('StorageOutKu.title')" :visible.sync="shows" direction="rtl" size="1000" @close="close">
    <div class="_info">
      <div class="detail_info">
        <p class="title">{{ $t('StorageOutKu.detailed') }}</p>
        <div class="content">
          <div class="content_item">
            <p>
              <span>{{ $t('StorageOutKu.OutKuDanCode') }}:</span> <!-- 出库编号-->
              <font>{{ model.OutKuDanCode }}</font>
            </p>
            <p>
              <span>{{ $t('StorageOutKu.OutKuTime') }}:</span><!-- 出库时间-->
              <font>{{ model.OutKuTime }}</font>
            </p>
          </div>
          <div class="content_item">
            <p>
              <span>{{ $t('StorageOutKu.WarehouseName') }}:</span><!-- 库房名称-->
              <font v-if="$getBytes(model.WarehouseName) < 40">{{ model.WarehouseName }}</font>
              <el-popover v-else placement="top-start" width="200" trigger="hover" :content="model.WarehouseName">
                <font slot="reference">{{ model.WarehouseName }}</font>
              </el-popover>
            </p>
            <p>
              <span>{{ $t('StorageOutKu.Operator') }}:</span><!-- 经办管理员-->
              <font v-if="$getBytes(model.Operator) < 40">{{ model.Operator }}</font>
              <el-popover v-else placement="top-start" width="200" trigger="hover" :content="model.Operator">
                <font slot="reference">{{ model.Operator }}</font>
              </el-popover>
            </p>
          </div>
          <div class="content_item">
            <p>
              <span>{{ $t('StorageOutKu.OutKuDeptName') }}:</span><!-- 领用部门-->
              <font v-if="$getBytes(model.OutKuDeptName) < 40">{{ model.OutKuDeptName }}</font>
              <el-popover v-else placement="top-start" width="200" trigger="hover" :content="model.OutKuDeptName">
                <font slot="reference">{{ model.OutKuDeptName }}</font>
              </el-popover>
            </p>
            <p>
              <span>{{ $t('StorageOutKu.OutKuUserName') }}:</span><!-- 领用人-->
              <font v-if="$getBytes(model.OutKuUserName) < 40">{{ model.OutKuUserName }}</font>
              <el-popover v-else placement="top-start" width="200" trigger="hover" :content="model.OutKuUserName">
                <font slot="reference">{{ model.OutKuUserName }}</font>
              </el-popover>
            </p>
          </div>
          <div class="content_item">
            <p>
              <span>{{ $t('StorageOutKu.CreateTimeText') }}:</span><!-- 出库时间-->
              <font>{{ model.CreateTime }}</font>
            </p>
            <p>
              <span>{{ $t('StorageOutKu.OutKuStatus') }}:</span><!-- 出库状态 -->
              <font :style="`color:${model.OutKuStatusColor}`">{{ model.OutKuStatusName }}</font>
            </p>
          </div>
          <div class="content_item">
            <p>
              <span>{{ $t('StorageOutKu.OutKuRemark') }}:</span><!-- 出库描述 -->
              <font v-if="$getBytes(model.OutKuRemark) < 40">{{ model.OutKuRemark }}</font>
              <el-popover v-else placement="top-start" width="200" trigger="hover" :content="model.OutKuRemark">
                <font slot="reference">{{ model.OutKuRemark }}</font>
              </el-popover>
            </p>

          </div>
        </div>
      </div>
      <div class="Download">
        <div style="float: right">
          <a :href="downUrl" style="color: #3260f6;cursor: pointer;"><i class="el-icon-download"></i> {{ $t('StorageOutKu.DownloadDetails') }}</a> <!--下载明细-->
          <span class="Refresh"><i class="el-icon-setting" @click="TableOk = true" style="color:#165dff;cursor: pointer;">
              {{ $t('BasicDept.CustomizeHeader') }}</i> </span> <!--自定义表头-->
        </div>
        <div>

          <span style="font-size: 14px">{{ $t('StorageOutKu.MaterialDetails') }}</span> <!--物资明细单-->

        </div>
      </div>
      <el-table border :data="list" height="calc(100vh - 440px)" @header-dragend="headerDragend">
        <el-table-column type="index" width="55" :label="$t('StorageOutKu.NumBer')"> </el-table-column>
        <el-table-column :sortable="item.sort" v-for="(item, index) in GoodsTable" :key="index" :prop="item.propName" :label="item.labelName" :width="item.width">
          <template slot-scope="scope">
            <ImageView v-if="item.propName=='HuoPinImage'" :url="scope.row.HuoPinImage"></ImageView>
            <span v-else>
              {{ scope.row[item.propName] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-row style="height: 50px; padding-top: 10px">
        <el-col :span="20" class="table_Price">
          {{ $t('StorageInKu.Count') }}{{ OutKuNumber }} {{ $t('StorageInKu.SumMoney') }}{{ OutKuAllPrice }}
        </el-col>
      </el-row>
    </div>
    <DragTable v-if="TableOk" TableName="StorageOutKuDetail" :show="TableOk" @message="Message_">
    </DragTable>
  </el-drawer>
</template>

<script>
import {
  LoadBasicUserColumn,
  SetBasicUserColumnWidth

} from "@/api/user";
export default {
  props: {
    form: {
      type: Object
    },
    Notes: {
      type: Array,
      default() {
        return []
      }
    },
    show: {
      type: Boolean
    }
  },
  computed: {
    OutKuNumber() {
      let sum = 0;
      for (let i = 0; i < this.list.length; i++) {
        sum = parseInt(this.list[i].OutKuCount || 0) + parseInt(sum);
      }
      return sum || 0;
    },
    OutKuAllPrice() {
      let sum = 0;
      for (let i = 0; i < this.list.length; i++) {
        sum = parseFloat(parseFloat(sum) + parseFloat(this.list[i].AllPrice)).toFixed(2);
      }
      return sum || 0;
    },
  },
  data() {
    return {
      downUrl: '',
      model: {},
      list: [],
      GoodsTable: [],
      TableOk: false,
      shows: false
    }
  },

  mounted() {
    this.model = this.form;
    for (let item in this.model) {
      if (item.toUpperCase().includes('TIME')) {
        this.model[item] = this.$transferTime(this.model[item]);
      }
    }
    let lang = localStorage.getItem("locale") || "zh_CN";
    this.downUrl = this.$url + '/StorageOutKuDan/OutKuDownExcel?code=' + this.form.OutKuDanID+'&UserID='+this.$store.state.userinfo.UserID + '&Language=' + lang;
    this.list = this.Notes;
    this.shows = this.show;
    this.getTableColumn();
  },
  methods: {
    // 表头拖动事件
    headerDragend(newWidth, data, e) {
      data
      if (e.property!=null) {
        SetBasicUserColumnWidth({ TableKey: 'StorageOutKuDetail', ColumnKey: e.property, Width: newWidth }).then(res => {
        if (res.Code == 0) {
          res
        }
      })
      } 
    },
    // 表单加载
    getTableColumn() {
      LoadBasicUserColumn({ TableKey: 'StorageOutKuDetail' }).then(res => {
        if (res.Code == 0) {
          this.GoodsTable = res.Data;
        }
      })
    },
    //刷新
    Message_(e) {
      if (e == 0) {
        this.getTableColumn();
      }
      this.TableOk = false;
      this.list = [];

      setTimeout(() => {
        this.list = this.Notes;
      }, 500)
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
span.Refresh {
  margin-left: 15px;
}
.el-dialog__title {
  font-size: 16px;
  color: #1d2129;
}

span.el-dialog__title {
  margin-left: 8px;
}

.content_item {
  margin-top: 12px;
}

.el-dialog__body {
  padding: 0px 32px;
}

.Download {
  margin-bottom: 17px;
}

.detail_info {
  margin-bottom: 25px;
}

.textarea {
  width: 75%;
}

a {
  text-decoration: none;
  color: #409eff;
}
/deep/.el-drawer {
  width: 1000px;
}
</style>