<template>
  <div class="_dialog">
    <div class="_top">
      <i @click="close" style="cursor: pointer;" class="el-icon-d-arrow-left"></i>
      <span @click="close">{{ $t('StorageGoods.back') }}</span>
      <div style="width: 1px;height: 20px;background-color:#e5e6eb;"></div>
      <font></font>
      <el-tabs v-model="addType" @tab-click="selType">
        <el-tab-pane :label="$t('StorageInKu.newadd')" name="newadd" :disabled="InKuOpertion.Opertion == 'Update'">
        </el-tab-pane>
        <el-tab-pane :label="$t('StorageInKu.capture')" name="oldadd" :disabled="InKuOpertion.Opertion == 'Update'">
        </el-tab-pane>
      </el-tabs>
    </div>
    <div v-if="addType == 'newadd'" class="_tabs_item" style="height: calc(100vh - 235px);">
      <el-form ref="form" style="padding:24px;padding-bottom:0px" :rules="rules" label-position="right" label-width="100px" input-width="100px" :model="StorageInKuDan">
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('StorageInKu.InKuUserName')" prop="InKuUserName">
              <el-input :placeholder="$t('StorageInKu.choice')" v-model="StorageInKuDan.InKuUserName" @input="changeDraft($event, 'InKuUserName', 0)" readonly
                class="input-with-select">
                <el-button v-if="detailState" slot="append" icon="el-icon-user-solid" @click="userClick(StorageInKuDan)"></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('StorageInKu.BatchNumber')">
              <el-input :placeholder="$t('StorageInKu.generate')" v-model="StorageInKuDan.BatchNumber" @input="changeDraft($event, 'BatchNumber', 0)" readonly
                class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('StorageInKu.WarehouseName')" prop="WarehouseName">
              <el-input v-if="detailState == true" :placeholder="$t('StorageInKu.choice')" v-model="StorageInKuDan.WarehouseName" @input="changeDraft($event, 'WarehouseName', 0)"
                readonly class="input-with-select">
                <el-button v-if="detailState" slot="append" icon="el-icon-thumb" @click="RoomClick(StorageInKuDan)"></el-button>
              </el-input>
              <el-input v-else :placeholder="$t('StorageInKu.WarehouseName')" :readonly="detailState == false" v-model="StorageInKuDan.WarehouseName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('StorageInKu.Supplier')">
              <el-input v-if="detailState" maxlength="128" show-word-limit :placeholder="$t('StorageInKu.Supplier')" :readonly="inShow" v-model="StorageInKuDan.Supplier"
                @input="changeDraft($event, 'Supplier', 0)"></el-input>
              <el-input v-else :placeholder="$t('StorageInKu.Supplier')" readonly v-model="StorageInKuDan.Supplier" @input="changeDraft($event, 'Supplier', 0)"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('StorageInKu.InKuTime')">
              <el-date-picker :clearable="false" v-model="StorageInKuDan.InKuTime" @change="changeDraft($event, 'InKuTime', 0)" type="datetime" :placeholder="$t('AssetHandOverAdd.tips2')">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('StorageInKu.InKuRemark')">
              <el-input maxlength="512" show-word-limit type="textarea" rows="2" :readonly="inShow" v-model="StorageInKuDan.InKuRemark"
                @input="changeDraft($event, 'InKuRemark', 0)"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-divider></el-divider>
      <div style="display: flex;align-items: center;width: 100%;justify-content: space-between;margin-bottom: 10px;height: 40px;">
        <div style="display: flex;align-items: center;">
          <el-button icon="el-icon-plus" class="button_primary" :disabled="addType == 'oldadd'" @click="AddHuopin()" v-if="inShow == false">
            {{ $t('StorageInKu.AddGoods') }}<!--增加新物资-->
          </el-button>
          <el-button icon="el-icon-edit" class="button_info" @click="AddBatch()" v-if="inShow == false">
            {{ $t('StorageInKu.BatchInput') }}
          </el-button>
          <el-button icon="el-icon-delete" class="button_info" @click="DelGoods()" :disabled="goodsChecked" v-if="!inShow">{{
              $t('StorageInKu.DeleteGoods') }}<!--删除所选-->
          </el-button>
        </div>
        <span class="Refresh"><i class="el-icon-setting" @click="TableOk = true" style="color:#165dff;cursor: pointer;">
            {{ $t('BasicDept.CustomizeHeader') }}</i> </span> <!--自定义表头-->
      </div>
      <div style="position: relative;width: 100%;height: calc(100vh - 550px);">
        <el-table border style="position: absolute;" :data="InKuNotes" height="calc(100vh - 550px)" stripe @selection-change="handleSelectionChange1" ref="dialogList1"
          :row-class-name="selectRowClassName1" @row-click="handleRowClick1" @header-dragend="headerDragend">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column type="index" width="55" :label="$t('StorageInKu.NumBer')"> </el-table-column>
          <el-table-column :sortable="item.sort" v-for="(item, index) in TableList" :key="index" :prop="item.propName" :label="item.labelName" :width="item.width">
            <template slot-scope="scope">
              <ImageView v-if="item.propName == 'HuoPinImage'" :url="scope.row.HuoPinImage || scope.row.GoodsImage">
              </ImageView>
              <el-input :placeholder="$t('StorageInKu.InputCount')" oninput="value=value.replace(/[^\d]/g,'')" type=""
                v-else-if="InKuOpertion.Opertion == 'Add' && item.propName == 'InKuCount'" class="_table_input" :ref="`inputRef${scope.$index + 1}`" v-model="scope.row.FactStock"
                :data-rowindex="'FactStock' + scope.$index" @keydown.enter.tab.native="focusTabInput(scope.$index + 1)"></el-input>
              <el-input :placeholder="$t('StorageInKu.InputCount')" oninput="value=value.replace(/[^\d]/g,'')" type=""
                v-else-if="InKuOpertion.Opertion == 'Update' && item.propName == 'InKuCount'" class="_table_input" :ref="`inputRef${scope.$index + 1}`"
                v-model="scope.row.InKuCount" :data-rowindex="'FactStock' + scope.$index" @keydown.enter.tab.native="focusTabInput(scope.$index + 1)"></el-input>
              <span v-else-if="item.propName == 'AllPrice'">
                {{ InKuOpertion.Opertion == 'Add' ? (scope.row.FactStock || 0) * scope.row.DefaultPrice :
                  (scope.row.InKuCount || 0) * scope.row.DefaultPrice }}
              </span>
              <el-popover v-else-if="item.propName == 'InKuRemark'" placement="top-start" width="200" trigger="click">
                <span>{{ scope.row.InKuRemark }}</span>
                <span slot="reference">{{ scope.row.InKuRemark }}</span>
              </el-popover>
              <span v-else>
                {{ scope.row[item.propName] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" :label="$t('StorageGoods.Operate')" width="100" align="center">
            <template slot-scope="scope">
              <div class="_operate">
                <span class="waring" @click.stop="delGoods(scope.row)">{{ $t('StorageGoods.Del') }}</span>
              </div>
            </template>
          </el-table-column>
          <div slot="empty" class="empty">
            <img src="@/assets/img/empty.png" />
            <span class="txt">{{ $t('StorageGoods.none') }}
              <font @click="AddHuopin()">{{ $t('StorageInKu.AddGoods') }}</font>~
            </span>
          </div>
        </el-table>
      </div>
      <el-row style="height: 30px; padding-top: 10px;width:50%">
        <el-col :span="20" v-if="InKuOpertion.Opertion == 'Update'" class="table_Price">
          {{ $t('StorageInKu.Count') }}{{ InKuNumberUpdate }} {{ $t('StorageInKu.SumMoney') }}{{ InKuAllPriceUpdate }}
        </el-col>
        <el-col :span="20" v-else class="table_Price">
          {{ $t('StorageInKu.Count') }}{{ InKuNumber }} {{ $t('StorageInKu.SumMoney') }}{{ InKuAllPrice }}
        </el-col>
      </el-row>
    </div>
    <div v-else class="_tabs_item" style="height: calc(100vh - 235px);">
      <el-form ref="form2" style="padding:24px;padding-bottom:0px" :rules="rules" label-position="right" label-width="100px" input-width="100px" :model="StorageInKuDan">
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('StorageInKu.InKuUserName')" prop="InKuUserName"> <!-- 入库人-->
              <el-input :placeholder="$t('StorageInKu.choice')" v-model="StorageInKuDan.InKuUserName" @input="changeDraft($event, 'InKuUserName', 0)" readonly
                class="input-with-select">
                <el-button v-if="detailState" slot="append" icon="el-icon-user-solid" @click="userClick(StorageInKuDan)"></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('StorageInKu.BatchNumber')"> <!-- 批次号-->
              <el-input :placeholder="$t('StorageInKu.generate')" v-model="StorageInKuDan.BatchNumber" @input="changeDraft($event, 'BatchNumber', 0)" readonly
                class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('StorageInKu.WarehouseName')" prop="WarehouseName"> <!-- 请选择库房-->
              <el-input :placeholder="$t('StorageInKu.choice')" v-model="StorageInKuDan.WarehouseName" @input="changeDraft($event, 'WarehouseName', 0)" readonly
                class="input-with-select">
                <el-button v-if="detailState" slot="append" icon="el-icon-thumb" @click="RoomClick(StorageInKuDan)"></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('StorageInKu.Supplier')"> <!-- 供应商-->
              <el-input v-if="detailState" maxlength="128" show-word-limit :placeholder="$t('StorageInKu.Supplier')" :readonly="inShow" v-model="StorageInKuDan.Supplier"
                @input="changeDraft($event, 'Supplier', 0)"></el-input>
              <el-input v-else :placeholder="$t('StorageInKu.Supplier')" readonly v-model="StorageInKuDan.Supplier" @input="changeDraft($event, 'Supplier', 0)"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('StorageInKu.InKuTime')">
              <el-date-picker v-model="StorageInKuDan.InKuTime" @change="changeDraft($event, 'InKuTime', 0)" type="datetime" :placeholder="$t('AssetHandOverAdd.tips2')">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('StorageInKu.InKuRemark')"> <!-- 入库备注-->
              <el-input v-if="detailState" maxlength="512" show-word-limit type="textarea" rows="2" :readonly="inShow" v-model="StorageInKuDan.InKuRemark"
                @input="changeDraft($event, 'InKuRemark', 0)"></el-input>
              <el-input v-else type="textarea" class="textarea" rows="2" :readonly="detailState ? inShow : !detailState" v-model="StorageInKuDan.InKuRemark"
                @input="changeDraft($event, 'InKuRemark', 0)"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-divider></el-divider>
      <div style="display: flex;align-items: center;width: 100%;justify-content: space-between;margin-bottom: 10px;height: 40px;">
        <div style="display: flex;align-items: center;">
          <el-button icon="el-icon-plus" class="button_primary" :disabled="addType == 'newadd'" @click="AddOldGoods()" v-if="inShow == false">{{
              $t('StorageInKu.replenishment') }}<!--增加补货物资-->
          </el-button>
          <el-button icon="el-icon-edit" class="button_info" @click="AddBatch()" v-if="inShow == false">
            {{ $t('StorageInKu.BatchInput') }}
          </el-button>
          <el-button icon="el-icon-delete" class="button_info" @click="DelGoods()" :disabled="goodsChecked" v-if="!inShow">{{
              $t('StorageInKu.DeleteGoods') }}<!--删除所选-->
          </el-button>
        </div>
        <div>
          <span class="Refresh"><i class="el-icon-setting" @click="TableOk = true" style="color:#165dff;cursor: pointer;">
              {{ $t('BasicDept.CustomizeHeader') }}</i> </span> <!--自定义表头-->
        </div>
      </div>
      <div style="position: relative;width: 100%;height: calc(100vh - 550px);">
        <el-table border style="position: absolute;" :data="InKuNotes" height="calc(100vh - 550px)" stripe @selection-change="handleSelectionChange1" ref="dialogList2"
          :row-class-name="selectRowClassName1" @row-click="handleRowClick2" @header-dragend="headerDragend2">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column :sortable="item.sort" v-for="(item, index) in TableList_" :key="index" :prop="item.propName" :label="item.labelName" :width="item.width">
            <template slot-scope="scope">
              <ImageView v-if="item.propName == 'HuoPinImage'" :url="scope.row.HuoPinImage || scope.row.GoodsImage">
              </ImageView>
              <el-input :placeholder="$t('StorageInKu.InputCount')" oninput="value=value.replace(/[^\d]/g,'')" type=""
                v-else-if="InKuOpertion.Opertion == 'Add' && item.propName == 'InKuCount'" class="_table_input" :ref="`inputRef${scope.$index + 1}`" v-model="scope.row.FactStock"
                :data-rowindex="'FactStock' + scope.$index" @keydown.enter.tab.native="focusTabInput(scope.$index + 1)"></el-input>
              <el-input :placeholder="$t('StorageInKu.InputCount')" oninput="value=value.replace(/[^\d]/g,'')" type=""
                v-else-if="InKuOpertion.Opertion == 'Update' && item.propName == 'InKuCount'" class="_table_input" :ref="`inputRef${scope.$index + 1}`"
                v-model="scope.row.InKuCount" :data-rowindex="'FactStock' + scope.$index" @keydown.enter.tab.native="focusTabInput(scope.$index + 1)"></el-input>
              <el-popover v-else-if="item.propName == 'InKuRemark'" placement="top-start" width="200" trigger="click">
                <span>{{ scope.row.InKuRemark }}</span>
                <span slot="reference">{{ scope.row.InKuRemark }}</span>
              </el-popover>
              <span v-else-if="item.propName == 'AllPrice'">
                {{ InKuOpertion.Opertion == 'Add' ? scope.row.FactStock * scope.row.DefaultPrice :
                  scope.row.InKuCount * scope.row.DefaultPrice }}
              </span>
              <span v-else>
                {{ scope.row[item.propName] }}
              </span>
            </template>
          </el-table-column>
          <div slot="empty" class="empty">
            <img src="@/assets/img/empty.png" />
            <span class="txt">{{ $t('StorageGoods.none') }}
              <font @click="AddOldGoods()">{{ $t('StorageInKu.replenishment') }}</font>~
            </span>
          </div>
        </el-table>
      </div>
      <el-row style="height: 30px; padding-top: 10px;width:50%">
        <el-col :span="20" v-if="InKuOpertion.Opertion == 'Update'" class="table_Price">
          {{ $t('StorageInKu.Count') }}{{ InKuNumberUpdate }} {{ $t('StorageInKu.SumMoney') }}{{ InKuAllPriceUpdate }}
        </el-col>
        <el-col :span="20" v-else class="table_Price">
          {{ $t('StorageInKu.Count') }}{{ InKuNumber }} {{ $t('StorageInKu.SumMoney') }}{{ InKuAllPrice }}
        </el-col>
      </el-row>
    </div>
    <div class="_footer">
      <el-button class="button_info" @click="close()" style="margin-right: 7px">{{ $t('StorageInKu.cancel')
      }}</el-button>
      <el-button v-if="InKuOpertion.Opertion == 'Add'" class="button_primary" @click="addInfo()">{{
        $t('StorageInKu.confirm')
      }}</el-button>
      <el-button v-else class="button_primary" @click="updateInfo()">{{ $t('StorageInKu.confirm') }}</el-button>
    </div>
    <!--批量填充-->
    <el-dialog :title="$t('StorageInKu.BatchInput')" :visible.sync="dialogVisible" width="30%" append-to-body>
      <el-input v-model="batchNumber" oninput="value=value.replace(/[^\d]/g,'')" :placeholder="$t('StorageInKu.BatchInputNews')" style="width: 100%;"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button class="button_info" @click="dialogVisible = false">{{ $t('StorageGoods.cancel') }}</el-button>
        <el-button class="button_primary" @click="BatchInput()">{{ $t('StorageGoods.confirm') }}</el-button>
      </span>
    </el-dialog>
    <!-- 领用人组件 -->
    <AdminUser v-if="type" :type="type" @close="type = false" @addMember="addInkuUser" :DataList="UserList"></AdminUser>
    <!-- 库房组件 -->
    <SelectWarehouse v-if="Roomtype" :type="Roomtype" @close="Roomtype = false" @addMember="addRoom" :DataList="RoomList">
    </SelectWarehouse>
    <!-- 货品组件 -->
    <CategoryList v-if="gooodsType" :type="gooodsType" @closed="gooodsType = false" :DataList="DataList" @addHuopin="addHuopin">
    </CategoryList>
    <!-- 物资组件 -->
    <SystemGoods v-if="oldGoodsType" :type="oldGoodsType" :warehouseid="StorageInKuDan.WarehouseID" :DataList="DataList" @close="oldGoodsType = false" @addGoods="addGoods">
    </SystemGoods>
    <DragTable v-if="TableOk" :TableName="TableName" :show="TableOk" @message="Message_">
    </DragTable>
  </div>
</template>

<script>
import CategoryList from "@/components/CategoryList.vue";
import {
  successTips,
  getInKuDanStatus,
  GetStorageWarehouseTree,
  addInkuDan,
  getInKuDetail,
  updateTime,
  updateInKuDan,
  SetBasicUserColumnWidth,
  msgTips,
  LoadBasicUserColumn,
  AddStorageGoodsInKuDan,
  GetStorageShelfPage
} from "@/api/user";
import draft from '@/mixins/draft';
export default {
  props: {
    show: {
      type: Boolean,
    },
    InkuID: {
      type: Number,
      default: 0
    },
    InKuTitle: {
      type: String,
      default: ''
    },
    InKuOpertion: {
      type: Object,
    },
    state: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  computed: {
    InKuNumber() {
      let sum = 0;
      for (let i = 0; i < this.InKuNotes.length; i++) {
        sum = parseInt(this.InKuNotes[i].FactStock || 0) + parseInt(sum);
      }
      return sum || 0;
    },
    InKuNumberUpdate() {
      let sum = 0;
      for (let i = 0; i < this.InKuNotes.length; i++) {
        sum = parseInt(this.InKuNotes[i].InKuCount || 0) + parseInt(sum);
      }
      return sum || 0;
    },
    InKuAllPrice() {
      let sum = 0;
      for (let i = 0; i < this.InKuNotes.length; i++) {
        sum = parseFloat(parseFloat(sum) + parseFloat((this.InKuNotes[i].DefaultPrice * (this.InKuNotes[i].FactStock || 0)))).toFixed(2);
      }
      return sum || 0;
    },
    InKuAllPriceUpdate() {
      let sum = 0;
      for (let i = 0; i < this.InKuNotes.length; i++) {
        sum = parseFloat(parseFloat(sum) + parseFloat((this.InKuNotes[i].DefaultPrice * (this.InKuNotes[i].InKuCount || 0)))).toFixed(2);
      }
      return sum || 0;
    }
  },
  mixins: [draft],
  name: "SettingView",
  components: { CategoryList },
  data() {
    return {
      Roomtype: false,
      inShow: false,
      TableOk: false,
      detailState: true,
      ID: "",
      BatchInku: false,
      filterText: "",
      title: "",
      Title: "",
      TableName: 'StorageInKuAddH',
      type: false,
      TableType: 0,
      gooodsType: false,
      oldGoodsType: false,
      checked: true,
      goodsChecked: true,
      dialogFormVisible: false,
      InKuDanID: '',
      DataList: [],
      TableList: [],
      TableList_: [],
      UserList: [],
      InKuNotes: [],
      RoomList: [],
      StorageInKuDan: {
        InKuDanCode: "",
        BatchNumber: "",
        GoodsDetail: "",
        WarehouseID: "",
        WarehouseName: "",
        Supplier: "",
        FactStock: 0,
        InKuUserID: "",
        InKuUserName: "",
        addType: "",
        Operator: "",
        InKuStatus: "",
        InKuStatusName: "",
        InKuRemark: "",
        InKuTime: new Date(),
        UpdateTime: "",
        CreateTime: "",
        InKuShelfID: ''
      },
      data: [],

      value: null,
      OperateType: "",
      NotesID: null,
      rules: {
        InKuUserName: [{ required: true, message: this.$t('StorageInKu.RulesInKuUser'), trigger: "blur" }],
        WarehouseName: [{ required: true, message: this.$t('StorageInKu.RulesWarehouseName'), trigger: "blur" }],
      },
      options: [
        {
          label: "未知",
          value: 0,
        },
        {
          label: "已确认",
          value: 1,
        },
        {
          label: "待确认",
          value: 2,
        },
        {
          label: "已驳回",
          value: 3,
        },
        {
          label: "草稿",
          value: 4,
        },
      ],

      multipleSelection: [],
      multipleSelection1: [],
      upload: false,
      selectTable: [],
      addType: 'newadd',
      draftTitle: ['StorageInKuDan'],
      batchNumber: null,
      Inoptions: [],
      InKuNotesList: [],
      dialogVisible: false
    };
  },
  mounted() {
    this.getWarehouseTree();
    this.getTableColumn();
    this.getTableColumn_();
    this.dialogFormVisible = this.show;
    if (this.InKuOpertion.Opertion == 'Add') {
      this.InKuNotes = [];
    }
    if (this.state && this.InKuOpertion.Opertion == 'Add') {
      this.StorageInKuDan = JSON.parse(window.localStorage.getItem(this.draftTitle[this.draftIndex]));
      this.addType = this.StorageInKuDan.addType;
      if (this.addType == "") {
        this.addType = "newadd";
      }
      this.GetShelfData(this.StorageInKuDan.WarehouseID);
      this.InKuNotes = JSON.parse(window.localStorage.getItem('StorageInKuNotes')) || [];
    }
    if (this.InKuOpertion.Opertion == 'Update') {
      getInKuDetail({ InKuDanID: this.InkuID }).then((res) => {
        if (res.Code == 0) {
          this.StorageInKuDan = res.Data;
          this.StorageInKuDan.InKuTime = updateTime(this.StorageInKuDan.InKuTime);
          this.GetShelfData(this.StorageInKuDan.WarehouseID);
          this.InKuNotes = res.Data.DetailList;
          if (res.Data.DetailList) {
            if (res.Data.DetailList[0].GoodsID != 0) {
              this.addType = "oldadd";
            } else {
              this.addType = "newadd";
            }
          }
        }
      });
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyprevent)
  },
  methods: {
    // 表单加载
    getTableColumn() {
      LoadBasicUserColumn({ TableKey: 'StorageInKuAddH' }).then(res => {
        if (res.Code == 0) {
          this.TableList = res.Data;
        }
      })
    },
    // 表单加载
    getTableColumn_() {
      LoadBasicUserColumn({ TableKey: 'StorageInKuAddG' }).then(res => {
        if (res.Code == 0) {
          this.TableList_ = res.Data;
        }
      })
    },
    //刷新
    Message_() {
      this.getTableColumn();
      this.InKuNotesList = this.InKuNotes;
      this.InKuNotes = this.InKuNotesList;
      this.TableOk = false;
    },
    getShelf() {
      let form = {
        PageIndex: 0,
        PageSize: 100,
        WarehouseID: this.StorageInKuDan.WarehouseID
      }
      GetStorageShelfPage(form).then(res => {
        if (res.Code == 0) {
          this.Inoptions = res.Data;
        }
      })
    },
    GetShelfData(e) {
      let form = {
        PageIndex: 0,
        PageSize: 100,
        WarehouseID: e
      }
      GetStorageShelfPage(form).then(res => {
        if (res.Code == 0) {
          this.Inoptions = res.Data;
        }
      })
    },
    selType(e) {
      if (e.name == 'oldadd') {
        this.TableName = 'StorageInKuAddG';
        this.getTableColumn_();
      } else {
        this.TableName = 'StorageInKuAddH';
        this.getTableColumn();
      }
      this.addType = e.name;
      this.InKuNotes = [];
      this.changeDraft(e.name, 'addType', 0)
      window.localStorage.setItem('StorageInKuNotes', null);
    },
    keyprevent(event) {
      if (event.keyCode === 9) {
        event.preventDefault()
      }
    },
    //调拨人点击事件
    userClick(data) {
      this.UserList = [];
      if (data) {
        this.type = true;
        this.UserList.push(data.InKuUserID);
      }
    },
    //库房点击事件
    RoomClick(data) {
      this.RoomList = [];
      if (data) {
        this.Roomtype = true;
        this.RoomList.push(data.WarehouseID);
      }
    },
    // 选择资产
    async addHuopin(e) {
      for (let i = 0; i < e.length; i++) {
        this.InKuNotes.push(e[i]);
      }
      let arr1 = this.unique(this.InKuNotes);
      this.InKuNotes = [];
      this.InKuNotes = arr1;
      this.gooodsType = false;

      window.localStorage.setItem('addType', 'newadd');
      this.addType = 'newadd';
    },
    // 选择物资
    async addGoods(e) {
      for (let i = 0; i < e.length; i++) {
        this.InKuNotes.push(e[i]);
      }
      let arr1 = this.unique1(this.InKuNotes);
      this.InKuNotes = [];
      this.InKuNotes = arr1;
      this.oldGoodsType = false;
      this.addType = 'oldadd';
      window.localStorage.setItem('addType', 'oldadd');
    },
    //去重
    unique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.HuoPinID) && res.set(arr.HuoPinID, 1));
    },
    //去重
    unique1(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.GoodsID) && res.set(arr.GoodsID, 1));
    },
    //增加资产
    AddHuopin() {
      this.DataList = [];
      if (this.InKuNotes != null) {
        for (let i = 0; i < this.InKuNotes.length; i++) {
          this.DataList.push(this.InKuNotes[i].HuoPinID);
        }
      }
      this.addType = 'newadd';
      window.localStorage.setItem('addType', 'oldadd');
      this.gooodsType = true;
      this.OperateType = 'add';
    },
    //批量填充
    AddBatch() {
      this.dialogVisible = true;
      // if (this.BatchInku == false) {
      //   this.BatchInku = true;
      // } else {
      //   this.BatchInku = false;
      // }
    },
    //增加已有物资
    AddOldGoods() {
      this.DataList = [];
      if (this.InKuNotes != null) {
        for (let i = 0; i < this.InKuNotes.length; i++) {
          this.DataList.push(this.InKuNotes[i].GoodsID);
        }
      }
      this.oldGoodsType = true;

    },
    //清空表格数据
    close() {
      this.draftState[this.draftIndex] = false;
      if (this.InKuNotes.length != 0) {
        window.localStorage.setItem('StorageInKuNotes', JSON.stringify(this.InKuNotes))
      }
      this.$emit("close");
    },
    // 获取库存
    getWarehouseTree() {
      GetStorageWarehouseTree().then((res) => {
        if (res.Code == 0) {
          this.data = res.Data;
        }
      });
    },

    // 选择入库人 ID + 名称
    addInkuUser(e) {
      let data = "";
      if (this.addType == "newadd") {
        data = "form";
      } else {
        data = "form2";
      }
      this.StorageInKuDan.InKuUserID = e.UserID;
      this.StorageInKuDan.InKuUserName = e.UserName;
      this.changeDraft(e.UserID, 'InKuUserID', 0);
      this.changeDraft(e.UserName, 'InKuUserName', 0);
      this.$refs[data].clearValidate(['InKuUserName']);  //移除表单某个字段的校验 
      this.type = false;
    },
    addRoom(e) {
      let data = "";
      if (this.addType == "newadd") {
        data = "form";
      } else {
        data = "form2";
      }
      this.StorageInKuDan.WarehouseID = e.WarehouseID;
      this.StorageInKuDan.WarehouseName = e.WarehouseName;
      this.changeDraft(e.WarehouseID, 'WarehouseID', 0);
      this.changeDraft(e.WarehouseName, 'WarehouseName', 0);
      this.$refs[data].clearValidate(['WarehouseName']);  //移除表单某个字段的校验 
      this.Roomtype = false;
    },
    getStatus() {
      getInKuDanStatus().then((res) => {
        if (res.Code == 0) {
          if (res.Data) {
            this.options = [];
            for (let item of res.Data) {
              this.options.push({
                label: item.name,
                value: item.value,
              });
            }
          }
        }
      });
    },
    // 操作
    addInfo() {
      let data = "";
      if (this.addType == "newadd") {
        data = "form";
      } else {
        data = "form2";
      }

      this.$refs[data].validate((valid) => {
        if (valid) {
          let list = this.InKuNotes;
          if (list.length == 0) {
            msgTips(this.$t('StorageInKu.InKuMsgTips'));
            return;
          }
          let str = [];
          if (this.addType == 'oldadd') {
            for (let item of list) {
              let { GoodsID, FactStock, HuoPinRemark, DefaultPrice } = item;
              str.push({
                GoodsID,
                FactStock,
                GoodsRemark: HuoPinRemark,
                AllPrice: parseFloat(FactStock * DefaultPrice).toFixed(2),
              });
            }
          } else {
            for (let item of list) {
              let { HuoPinID, FactStock, HuoPinRemark, DefaultPrice } = item;
              str.push({
                HuoPinID,
                FactStock,
                GoodsRemark: HuoPinRemark,
                AllPrice: parseFloat(FactStock * DefaultPrice).toFixed(2),
              });
            }
          }
          this.StorageInKuDan.GoodsDetail = JSON.stringify(str);
          if (this.addType == 'oldadd') {
            AddStorageGoodsInKuDan(this.StorageInKuDan).then(res => {
              if (res.Code == 0) {
                successTips(res.Message);
                window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
                this.draftState[this.draftIndex] = false;
                window.localStorage.setItem('addType', '');
                window.localStorage.setItem('StorageInKuNotes', null);
                this.dialogFormVisible = false;
                this.$emit('message', res.Code);
              }
            })
          } else {
            addInkuDan(this.StorageInKuDan).then(res => {
              if (res.Code == 0) {
                successTips(res.Message);
                window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
                this.draftState[this.draftIndex] = false;
                window.localStorage.setItem('StorageInKuNotes', null);
                window.localStorage.setItem('addType', '');
                this.dialogFormVisible = false;
                this.$emit('message', res.Code);
              }
            })
          }
        }
      });

    },
    // 修改
    updateInfo() {
      let data = "";
      if (this.addType == "newadd") {
        data = "form";
      } else {
        data = "form2";
      }
      this.$refs[data].validate((valid) => {
        if (valid) {
          let list = this.InKuNotes;
          if (list.length == 0) {
            msgTips(this.$t('StorageInKu.InKuMsgTips'));
            return;
          }
          let form = {
            WarehouseID: this.StorageInKuDan.WarehouseID,
            Supplier: this.StorageInKuDan.Supplier,
            InKuUserID: this.StorageInKuDan.InKuUserID,
            InKuRemark: this.StorageInKuDan.InKuRemark,
            InKuShelfID: this.StorageInKuDan.InKuShelfID,
            InKuTime: this.StorageInKuDan.InKuTime,
            GoodsDetail: "",
          };
          let str = [];
          for (let item of list) {
            if (this.InKuOpertion.Opertion == 'Add') {
              let { HuoPinID, FactStock, HuoPinRemark, DefaultPrice } = item;
              str.push({
                HuoPinID,
                FactStock,
                GoodsRemark: HuoPinRemark,
                AllPrice: parseFloat(FactStock * DefaultPrice).toFixed(2),
              });

            } else {
              let { HuoPinID, InKuCount, InKuRemark, DefaultPrice } = item;
              str.push({
                HuoPinID,
                FactStock: InKuCount,
                GoodsRemark: InKuRemark,
                AllPrice: parseFloat(InKuCount * DefaultPrice).toFixed(2),
              });
            }
          }
          form.GoodsDetail = JSON.stringify(str);
          updateInKuDan({ InKuDanID: this.StorageInKuDan.InKuDanID, ...form }).then(res => {
            if (res.Code == 0) {
              successTips(res.Message);
              window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
              this.draftState[this.draftIndex] = false;
              window.localStorage.setItem('addType', '');
              window.localStorage.setItem('StorageInKuNotes', null);
              this.dialogFormVisible = false;
              this.$emit('message', res.Code);
            }
          }
          );
          this.detailState = true;
        }
      });
    },

    // 获取库房标识
    getWarehouseID(e) {
      this.StorageInKuDan.WarehouseID = e.id;
      this.WarehouseName = e.label;
      this.changeDraft(e.label, 'WarehouseName', 0);
      this.StorageInKuDan.InKuShelfID = null;
      this.StorageInKuDan.WarehouseName = e.label;
      this.getShelf();
    },
    //回车
    focusTabInput(rowIndex) {
      this.$nextTick(() => {
        this.$refs.dialogList.clearSelection();
        if (rowIndex < this.InKuNotes.length) {
          this.$refs[`inputRef${parseInt(rowIndex + 1)}`][0].focus();
          this.$refs.dialogList.toggleRowSelection(this.InKuNotes[rowIndex]);
        } else {
          this.$refs[`inputRef1`][0].focus();
          this.$refs.dialogList.toggleRowSelection(this.InKuNotes[0]);
        }
      })
    },
    // 表头拖动事件
    headerDragend(newWidth, data, e) {
      data
      if (e.property != null) {
        SetBasicUserColumnWidth({ TableKey: 'StorageInKuAddH', ColumnKey: e.property, Width: newWidth }).then(res => {
          if (res.Code == 0) {
            res
          }
        })
      }
    },
    // 表头拖动事件
    headerDragend2(newWidth, data, e) {
      data
      if (e.property != null) {
        SetBasicUserColumnWidth({ TableKey: 'StorageInKuAddG', ColumnKey: e.property, Width: newWidth }).then(res => {
          if (res.Code == 0) {
            res
          }
        })
      }
    },
    //点击选中表格
    handleRowClick1(row) {
      //  this.$refs.dialogList.clearSelection();
      // let index;
      // if (this.addType == 'oldadd') {
      //   index = this.multipleSelection1.findIndex(item => {
      //     // 判断已选数组中是否已存在该条数据
      //     return item.GoodsID == row.GoodsID
      //   });
      // } else {
      //   index = this.multipleSelection1.findIndex(item => {
      //     // 判断已选数组中是否已存在该条数据
      //     return item.HuoPinID == row.HuoPinID
      //   });
      // }
      // if (index == -1) {
      //   // 如果未存在，设置已选状态，并在list中添加这条数据
      //   this.$refs.dialogList.toggleRowSelection(row); //设置复选框为选中状态
      // } else {
      //   // 如果已存在，设置未选状态，并在list中删除这条数据
      //   this.$refs.dialogList.toggleRowSelection(row, false); //设置复选框为未选状态
      // }
      this.$refs.dialogList1.toggleRowSelection(row); //设置复选框为选中状态
    },
    //点击选中表格
    handleRowClick2(row) {
      this.$refs.dialogList2.toggleRowSelection(row); //设置复选框为选中状态
    },
    //选中表格背景色
    selectRowClassName1({ row }) {
      var color = "";
      this.multipleSelection1.forEach(item => {
        if (this.addType == 'oldadd') {
          if (item.GoodsID == row.GoodsID) {
            color = "warning-row";
          }
        } else {
          if (item.HuoPinID == row.HuoPinID) {
            color = "warning-row";
          }
        }
      });
      return color;
    },
    // dialog中的货品列表选中数据
    handleSelectionChange1(val) {
      if (val.length != 0) {
        this.selectTable = val;
      }
      this.multipleSelection1 = JSON.parse(JSON.stringify(val));
      if (val.length != 0) {
        this.goodsChecked = false;
        this.NotesID = val[0].HuoPinID;
      } else {
        this.goodsChecked = true;
      }
    },
    // 删除物资
    delGoods(e) {
      this.selectTable = [];
      this.selectTable.push(e);
      this.DelGoods();
    },
    // 删除货品
    DelGoods() {
      for (let i = 0; i < this.selectTable.length; i++) {
        let list = JSON.parse(JSON.stringify(this.InKuNotes));
        if (this.addType == 'oldadd') {
          this.InKuNotes = list.filter((item) => item.GoodsID != this.selectTable[i].GoodsID);
        } else {
          this.InKuNotes = list.filter((item) => item.HuoPinID != this.selectTable[i].HuoPinID);
        }
        if (this.InKuNotes.length == 0) {
          // this.addType = '';
          window.localStorage.setItem('addType', '');
        }

        window.localStorage.setItem('StorageInKuNotes', this.InKuNotes);
      }
    },
    BatchInput() {
      let list = this.InKuNotes.map(item => {
        if (this.InKuOpertion.Opertion == 'Add') {
          item.FactStock = this.batchNumber;
        } else if (this.InKuOpertion.Opertion == 'Update') {
          item.InKuCount = this.batchNumber;
        }
        return item
      })
      this.InKuNotes = JSON.parse(JSON.stringify(list));
      this.dialogVisible = false;
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__nav-wrap {
  width: 100% !important;
}

.el-divider--horizontal {
  margin: 10px 10px;
}

._dialog ._footer[data-v-d4a2d8e8] {
  padding: 0px 0 0 0;
}

._dialog {
  width: 100%;
  height: calc(100vh - 140px);
  padding: 0 20px 20px;
  background-color: #fff;

  ._top {
    height: 48px;
    display: flex;
    align-items: center;

    span {
      color: #4e5969;
      font-size: 14px;
      margin: 0 20px 0 5px;
      cursor: pointer;
    }

    font {
      font-size: 16px;
      font-weight: bold;
      margin-left: 20px;
    }
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 160px;
      height: 160px;
    }

    span {
      line-height: 30px;
    }

    font {
      color: #5c90ff;
      cursor: pointer;
    }
  }

  ._footer {
    padding: 10px 0 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.SearchName {
  margin-right: 0% !important;
}

.el-input {
  width: calc(100% - 50px) !important; 
}

.el-select {
  width: 75% !important;
  margin-right: 8%;
}

.el-textarea {
  // width: 88.5% !important;
  width: calc(100% - 50px) !important;
}

.el-dialog {
  height: 880px;
}

/deep/ .el-table__empty-block {
  width: 20%;
  min-width: 121%;
  max-width: 20%;
  padding-right: 20%;
}

::v-deep .is-required .el-form-item__label::before {
  content: "*";
  color: #ff0000;
  margin-left: 4px;
}

.companyimg {
  line-height: 150px;
}

.el-icon-plus {
  border: 1px dashed;
  // background-color: #8c939d;
}

.el-link {
  margin-right: 30px;
}

//头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 15px;
  color: #8c939d;
  width: 135px;
  height: 128;
  line-height: 120px;
  text-align: center;
}

.el-input--mini .el-input__inne {
  height: 39px;
}

.avatar {
  width: 135px;
  height: 128;
  display: block;
}

//
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

._table_input {
  border-bottom: 1px solid #eee;
  width: 80% !important;
  margin-right: 0px;
 
  /deep/.el-input__inner {
    text-align: center;
  }
}

::v-deep ._table_input.el-input {
  padding: 0px !important;
}

.settin-box {
  width: 100%;
  height: calc(100vh - 20px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;

  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;

    .content_left {
      height: calc(100vh - 60px);
      padding: 30px 10px;
      font-size: 19px;

      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 20px 10px;

        span {
          font-size: 14px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 10px 0;

          // &:hover {
          //   background-color: #409eff;
          //   color: #fff;
          //   cursor: pointer;
          // }

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .content_right {
      height: calc(100vh - 70px);
      position: absolute;
      width: 100%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        height: 80px;

        .el-input {
          width: 200px !important;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
        box-shadow: 0 0 5px rgb(201, 199, 199);
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}

._filter {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    // color: #1a73e8;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}

._filter_btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 100px;

  .el-button {
    width: 100px;

    &:nth-child(2) {
      margin-left: 50px;
    }
  }
}

.filter_box {
  ul {
    padding-left: 10px;
  }

  li {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    .date {
      // margin-left: 45px;
     

      .el-input:nth-child(2) {
        margin-top: 10px;
      }

      
    }
  }

  font {
    display: inline-block;
    width: 100px;
    font-size: 14px;
    color: #4e5969;
  }

  .el-select {
    width: 300px;
  }

 
  ._filter_btn {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 100px;

    .el-button {
      width: 100px;

      &:nth-child(2) {
        margin-left: 50px;
      }
    }
  }
}
</style>
