<template>
  <el-dialog v-loading="loading" :title="uptype == 'in' ? $t('ExportView.Import') : $t('ExportView.ExPort')"
    :modal="false" :visible.sync="exportState" @close="close()">
    <div v-show="uptype == 'in'" class="_upload_in">
      <div class="_upload_in_item">
        <input type="file" accept=".xls,.xlsx" ref="fileInput" @change="upLoadTable($event)">
        <span>{{ $t('ExportView.UploadTable') }}</span>
      </div>
      <div class="_item_footer" v-show="inTiele != ''">
        <span>{{ inTiele }}</span>
        <i class="el-icon-close" @click="ExportForm.upUrl = '', inTiele = '', clean()"></i>
      </div>
      <div class="_item_down">
        <span>{{ $t('ExportView.prompt') }}</span><a :href="downUrl">{{ $t('ExportView.download') }}</a>
      </div>
      <div class="_item_error" v-show="errSrc != ''">
        <span>{{ errString }}</span><a :href="errSrc">{{ $t('ExportView.download') }}</a>
      </div>
    </div>
    <div v-show="uptype == 'out'" class="_upload_out">
      <p>{{ $t('ExportView.ExportColumns') }}</p>
      <el-checkbox-group v-model="ExportForm.Head">
        <el-checkbox style="margin:5px 15px 0 0;" v-for="(item, index) in exportColumn" :key="index" :label="item">{{ item
        }}</el-checkbox>
      </el-checkbox-group>
      <p>{{ $t('ExportView.ExportType') }}</p>
      <div style="margin: 10px 0;">
        <el-radio v-model="ExportForm.ExportType" :label="'NowPage'">{{ $t('ExportView.ExportOrder') }}</el-radio>
        <el-radio v-model="ExportForm.ExportType" :label="'AllPage'">{{ $t('ExportView.ExportAll') }}</el-radio>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button class="button_info" @click="close()">{{ $t('ExportView.quit') }}</el-button>
      <el-button class="button_primary" type="primary" @click="submit()">{{ $t('ExportView.query') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>

import {
  msgTips,
  successTips,
  upLoadFile
} from "@/api/user";
import { getCookie } from '@/api/cookie'
export default {
  props: {
    exportColumn: {
      type: Array
    },
    uptype: {
      type: String
    },
    state: {
      type: Boolean,
      default: false
    },
    importUrl: {
      type: String
    },
    error: {
      type: String,
      default: ''
    },
    errUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      inTiele: '',
      exportState: false,
      loading: false,
      ExportForm: {
        Head: [],
        ExportType: 'NowPage',
        upUrl: ''
      },
      downUrl: '',
    }
  },
  computed: {
    errSrc() {
      console.log(this.errUrl);
      let lang = localStorage.getItem("locale") || "zh_CN";
      if (this.errUrl) {
        return this.$url + this.errUrl + `&language=${lang}`;
      } else {
        return '';
      }
    },
    errString() {
      return this.error;
    }
  },
  mounted() {
    this.ExportForm.Head = JSON.parse(JSON.stringify(this.exportColumn));
    this.exportState = this.state;
    let token = getCookie('token');
    let lang = localStorage.getItem("locale") || 'zh_CN'
    this.downUrl = this.$url + '/Import/ImportTemplate?' + this.importUrl + `&TOKEN=${token}&UserID=${this.$store.state.userinfo.UserID}&key=${lang}`;
  },
  methods: {
    close() {
      this.$emit('close');
    },
    clean() {
      this.$refs.fileInput.value = ''; // 清除文件输入
    },
    submit() {
      if (this.uptype == 'out') {
        if (this.ExportForm.Head.length == 0) {
          msgTips(this.$t('ExportView.SelectionPrompt'));
          return
        }
      } else {
        if (this.ExportForm.upUrl == '') {
          msgTips(this.$t('ExportView.Selectfile'));
          return
        }
      }
      this.$emit('submit', this.ExportForm, this.uptype);
    },
    // 导入表格的文件
    async upLoadTable(e) {
      setTimeout(() => {
        let file = e.target.files[0];
        let formData = new FormData();
        formData.append('file', file);
        upLoadFile(formData).then((res) => {
          if (res.Code == 0) {
            successTips(res.Message);
            this.ExportForm.upUrl = this.$url + res.Data;
            this.inTiele = file.name;
            this.loading = false;
          } else {
            successTips(res.Message);
            this.inTiele = '';
            this.loading = false;
          }
        });
      }, 1000);

    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #165dff;
  background: #165dff;
}

::v-deep .el-radio__input.is-checked+.el-radio__label {
  color: #165dff;
}
</style>