<template>
    <fc-designer ref="designer" height="100vh" />
</template>
<script>
export default {
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {
        
    }
}
</script>