<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  },
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}
</script>

<style></style>
