<template>
  <el-drawer title="单企业分析" :visible.sync="shows" size="1000" @close="close">
    <div class="_info" style="width: 1000px;">
      <div class="detail_info">
        <div class="content">
          <p class="title">基本分析</p>
          <div class="content_item">
            <p>
              <span>是否使用系统模块:</span>
              <font>{{ model.WhetherUseSystem }}</font>
            </p>
            <p>
              <span>是否使用仓储模块:</span>
              <font>{{ model.WhetherUseStorage }}</font>
            </p>
            <p>
              <span>是否使用资产模块:</span>
              <font>{{ model.WhetherUseAsset }}</font>
            </p>
            <p>
              <span>系统功能使用度:</span>
              <font>{{ model.SystemOperationUse }}</font>
            </p>
          </div>
          <div class="content_item">
            <p>
              <span>登录次数:</span>
              <font>{{ model.LoginCount }}</font>
            </p>
            <p>
              <span>最高活跃度:</span>
              <font>{{ model.VeryActive }}</font>
            </p>
            <p>
              <span>注册时间:</span>
              <font>{{ model.CreateTimeText }}</font>
            </p>
            <p>
              <span>最后登录时间:</span>
              <font>{{ model.LastLoginTime }}</font>
            </p>
          </div>
          <div class="content_item">
            <p>
              <span>最活跃时间:</span>
              <font>{{ model.VeryActiveTime }}</font>
            </p>
            <p>
              <span>最后使用时间:</span>
              <font>{{ model.LastUsageTime }}</font>
            </p>
            <p>
              <span></span>
              <font></font>
            </p>
            <p>
              <span></span>
              <font></font>
            </p>
          </div>
        </div>
        <div class="content">
          <p class="title">数据分析</p>
          <div class="content_item">
            <p>
              <span>员工数量:</span>
              <font>{{ model.UserCount }}</font>
            </p>
            <p>
              <span>部门数量:</span>
              <font>{{ model.DepeCount }}</font>
            </p>
            <p>
              <span>物资数量:</span>
              <font>{{ model.GoodsCount }}</font>
            </p>
            <p>
              <span>库房数量:</span>
              <font>{{ model.RoomCount }}</font>
            </p>
          </div>
          <div class="content_item">
            <p>
              <span>货架数量:</span>
              <font>{{ model.ShelfCount }}</font>
            </p>
            <p>
              <span>货品数量:</span>
              <font>{{ model.HuoPinCount }}</font>
            </p>
            <p>
              <span>种类数量:</span>
              <font>{{ model.CategoryCount }}</font>
            </p>
            <p>
              <span>区域数量:</span>
              <font>{{ model.DistrictCount }}</font>
            </p>
          </div>
          <div class="content_item">
            <p>
              <span>资产数量:</span>
              <font>{{ model.AssetCount }}</font>
            </p>
            <p>
              <span>分类数量:</span>
              <font>{{ model.TypeCount }}</font>
            </p>
            <p>
              <span>资产货品数量:</span>
              <font>{{ model.AssetHuoPinCount }}</font>
            </p>
            <p>
              <span></span>
              <font></font>
            </p>
          </div>
        </div>
      </div>
      <el-calendar>
        <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
        <template #dateCell="{data}">
          <p :class="data.isSelected ? 'is-selected' : ''">
            {{ data.day.split('-').slice(1).join('-') }}
          </p>
          <!-- 数组循环 -->
          <div class="cell" v-for="(item, index) in TimeTable" :key="index">
            <!-- 加数据 -->
            <div v-if="data.day == item.CreateTimeText">
              <div style="margin: 5px 0;">
                <el-tag>活跃度:{{item.hyd}}</el-tag>
              </div>
            </div>
          </div>
        </template>
      </el-calendar>
    </div>
  </el-drawer>
</template>

<script>
import {
  GetUseDayHyd,
} from "@/api/user";
export default {
  props: {
    form: {
      type: Object,
    },
    Notes: {
      type: Array,
      default() {
        return [];
      },
    },
    show: {
      type: Boolean,
    },
    id: {
      type: [Number, String]
    }
  },
  data() {
    return {
      downUrl: "",
      model: {},
      list: [],
      TimeTable: [],
      TableOk: false,
      itemKey: '',
      shows: false,
    };
  },
  mounted() {
    this.model = this.form;
    for (let item in this.model) {
      if (item.toUpperCase().includes('TIME')) {
        this.model[item] = this.$transferTime(this.model[item]);
      }
    }
    this.getData();
    this.shows = this.show;
  },
  methods: {
    // 表单加载
    getData() {
      GetUseDayHyd({ CompanyID: this.id }).then(res => {
        if (res.Code == 0) {
          this.TimeTable = res.Data;
        }
      })
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
span.Refresh {
  margin-left: 15px;
}

.el-dialog__title {
  font-size: 16px;
  color: #1d2129;
}
span.el-tag.el-tag--light {
  margin-top: -10px;
}
span.el-dialog__title {
  margin-left: 8px;
}

.content_item {
  margin-top: 12px;
}

.el-dialog__body {
  padding: 0px 32px;
}

.Download {
  margin-top: 17px;
}

.detail_info {
  margin-bottom: 25px;
}

.textarea {
  width: 75%;
}

a {
  text-decoration: none;
  color: #409eff;
}

/deep/.el-drawer {
  width: 1000px;
}
</style>